import Vue from "vue";

export async function indexAddAttachment() {
    const response = await Vue.prototype.$http.get("configs/eps/add_attachment");

    return response.data;
}

export async function updateMaxUploadSize(payload) {
    const response = await Vue.prototype.$http.post("configs/eps/add_attachment/max_upload_size", payload);

    return response.data;
}