<template>
    <ConfigPage>
        <ConfigItem 
            :title="__('Limite de tamanho', 'settings')" 
            :description="__('Define o tamanho máximo aceito por integração, todos os anexos maiores serão bloqueados antes do envio', 'settings')"
        >
            <FileSizeForm ref="maxUploadSizeForm" :title="__('Tamanho máximo do arquivo', 'settings')" @reset="getData()" @submit="submit" :loading="loading" />
        </ConfigItem>
    </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";

import FileSizeForm from "@/components/forms/configs/FileSizeForm";

import { indexAddAttachment, updateMaxUploadSize } from "@/services/configs/eps_add_attachment";
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    components: {
        ConfigPage,
        ConfigItem,
        FileSizeForm
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        async getData() {
            this.loading = true;
            const response = await indexAddAttachment();

            this.$refs.maxUploadSizeForm.fill(response.eps_max_attachments_upload_size);
            this.loading = false;
        },
        async submit(value) {
            this.loading = true;
            try {
                await updateMaxUploadSize({
                    eps_max_attachments_upload_size: value
                });
            } catch(error) {
                if(error.response?.status === 422) {
                    this.$refs.uploadLimit.setErrors(error.response.data.errors);
                }
            } finally {
                this.loading = false;
            }
        }
    },

    created() {
        this.getData();
    }
}
</script>